import { mapGetters } from 'vuex';
const value = (type) => ['number', 'string'].includes(typeof type);

export default {
  data() {
    return {
      creditAllowanceTotal: {
        allowance: 0,
        credit: 0,
      },
      appliedCreditAllowances: {
        allowance: false,
        credit: true,
      },
      totalOrderCharge: 0,
      existedTotalOrderCharges: 0,
      fundraisingTotal: 0,
      fundraisingTotalCost: 0,
      orderTotalWithCharges: 0,
      updated: 1,
    };
  },
  watch: {
    totalCart(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateTotalOrderCharge();
        if (this.storeInformation?.fundraising?.percentagePerOrder) this.calculateFundraisingTotal();
      }
    },
    shippingTotal(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateTotalOrderCharge();
        if (this.storeInformation?.fundraising?.percentagePerOrder) this.calculateFundraisingTotal();
      }
    },
    taxTotal(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateTotalOrderCharge();
        if (this.storeInformation?.fundraising?.percentagePerOrder) this.calculateFundraisingTotal();
      }
    },
    totalDiscount(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateTotalOrderCharge();
        this.calculateRemainingBalance();
        if (this.storeInformation?.fundraising?.percentagePerOrder) this.calculateFundraisingTotal();
      }
    },
    currentPaymentTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateTotalOrderCharge();
      }
    },
  },
  computed: {
    ...mapGetters({
      loggedInCustomer: 'auth/loggedInUser',
      storeInformation: 'storeInformation',
      creditsAllowances: 'creditsAllowances/creditsAllowances',
      storePaymentProvider: 'storePaymentProvider',
    }),
    orderTotalAndCreditAllowance() {
      const total = this.calculateTotalWithoutAdjusments();
      return (parseFloat(total.roundCeil()) - parseFloat(this.creditAllowanceSummary.roundCeil())).roundCeil();
    },
    remainingBalance() {
      return this.calculateRemainingBalance();
    },
    creditAllowancesForUse() {
      return ['Allowance', 'Credit']
        .map((i) => ({
          type: i.toLowerCase(),
          amount:
            this.creditsAllowances[i.toLowerCase()] &&
            this.creditsAllowances[i.toLowerCase()][0] &&
            this.creditsAllowances[i.toLowerCase()][0].sum,
          applied: null,
        }))
        .filter((i) => i.amount > 0);
    },
    creditAllowanceSummary() {
      const res = Object.values(this.creditAllowanceTotal).reduce((a, b) => a + b, 0);
      if (isNaN(res)) return 0;
      return res;
    },
    allCreditAllowancesApplied() {
      return this.appliedCreditAllowances.credit && this.appliedCreditAllowances.allowance;
    },
    orderTotalForCreditCard() {
      const s = this.calculateTotalWithoutAdjusments().roundCeil();
      return (
        (this.currentPaymentTab === 'Credit Card' && this.totalOrderCharge && this.orderTotalWithCharges > 0
          ? s + this.totalOrderCharge
          : s) + parseFloat(this.order.paymentsTotal || 0).roundCeil()
      );
    },
    hasAdditionalCharges() {
      return !!(
        this.storePaymentProvider?.processingFee ||
        this.storePaymentProvider?.processingCost ||
        this.storePaymentProvider?.processingPercentageFee ||
        this.storePaymentProvider?.processingPercentageCost
      );
    },
    isFeesExistWithoutProcessingData() {
      return this.order && this.order?.additionalOrderChargesTotal && this.existedTotalOrderCharges > 0;
    },
    isFeesExist() {
      return this.currentPaymentTab === 'Credit Card' && this.hasAdditionalCharges && this.totalOrderCharge > 0;
    },
  },
  methods: {
    calculateCreditAllowance() {
      return this.creditAllowanceSummary.roundCeil();
    },
    calculateTotalWithoutAdjusments() {
      const paymentsTotal = value(this.paymentsTotal) ? this.paymentsTotal : this.order.paymentsTotal || 0;
      const totalCart = value(this.totalCart)
        ? this.totalCart
        : parseFloat(this.order.productTotal) + parseFloat(this.order.chargeTotal) || 0;
      const taxTotal = value(this.taxTotal) ? this.taxTotal : this.order.taxTotal || 0;
      const shippingTotal = value(this.shippingTotal) ? this.shippingTotal : this.order.shippingTotal || 0;
      const productDiscount = value(this.productDiscount) ? this.productDiscount : this.cartProductDiscounts || 0;
      const shippingDiscount = value(this.shippingDiscount) ? this.shippingDiscount : this.order.shippingDiscount || 0;
      const totalDiscount = value(this.totalDiscount) ? this.totalDiscount : this.order.orderDiscount || 0;
      const fundraisingTotal = (value(this.fundraisingTotal) && this.fundraisingTotal) || 0;
      this.existedTotalOrderCharges = value(this.order.additionalOrderChargesTotal)
        ? this.order.additionalOrderChargesTotal
        : 0;
      return (
        parseFloat(totalCart) +
        parseFloat(shippingTotal) +
        parseFloat(taxTotal) -
        parseFloat(totalDiscount) -
        parseFloat(productDiscount) -
        parseFloat(shippingDiscount) -
        parseFloat(paymentsTotal) +
        parseFloat(fundraisingTotal) +
        parseFloat(this.existedTotalOrderCharges)
      ).roundCeil();
    },
    calculateRemainingBalance() {
      const total = this.orderTotalAndCreditAllowance.roundCeil();
      return (isNaN(total) ? 0 : total) + this.totalOrderCharge;
    },
    calculateTotalOrderCharge() {
      let total = this.calculateTotalWithoutAdjusments();

      const creditAllowance = this.calculateCreditAllowance();
      total = Math.max(0, total - creditAllowance);

      if (this.storePaymentProvider?.processingFee && this.currentPaymentTab === 'Credit Card' && total > 0) {
        this.totalOrderCharge = this.calculateFees(
          total,
          this.storePaymentProvider?.processingPercentageFee || 0,
          this.storePaymentProvider.processingFee
        );
      } else {
        this.totalOrderCharge = 0;
      }

      this.orderTotalWithCharges = total + this.totalOrderCharge;
    },
    calculateFundraisingTotal() {
      const fundraising = this.storeInformation?.fundraising || {};
      const amountPerOrder = fundraising.amountPerOrder || 0;
      const percentagePerOrder = fundraising.percentagePerOrder || 0;
      const productTotal = value(this.totalCart)
        ? this.totalCart
        : parseFloat(this.order.productTotal) + parseFloat(this.order.chargeTotal) || 0;
      const percentageAmount = (productTotal * percentagePerOrder) / 100;
      this.fundraisingTotal = amountPerOrder + percentageAmount.roundCeil();
      const percent = percentagePerOrder / 100;
      this.fundraisingTotalCost = (productTotal * percent + amountPerOrder).roundCeil();
    },
    calculateFees(total, processingPercentage, processingFee) {
      if (processingPercentage) {
        const percentage = processingPercentage / 100;
        const fee = ((total + processingFee) / (1 - percentage)).roundToNearest();
        return Math.max(0, (fee - total).roundToNearest());
      } else {
        return Math.max(0, processingFee);
      }
    },
    calculateFeesCost(total, processingPercentage, processingFee) {
      if (processingPercentage) {
        const percentage = processingPercentage / 100;
        const fee = (total * percentage + processingFee).roundToNearest();
        return fee;
      } else {
        return Math.max(0, processingFee).roundToNearest();
      }
    },
    applyCreditAllowance(index, value) {
      const data = this.creditAllowancesForUse[index];
      const parsedValue = parseFloat(value);
      if (parsedValue) {
        this.creditAllowanceTotal[data.type] += parsedValue;
        this.appliedCreditAllowances[data.type] = true;
        data.applied = parsedValue;
      } else {
        this.creditAllowanceTotal[data.type] = 0;
        this.appliedCreditAllowances[data.type] = false;
        data.applied = null;
      }
      this.error = null;
    },
    inputCreditAllowance(index, value) {
      const data = this.creditAllowancesForUse[index];
      const parsedValue = parseFloat(value);
      this.creditAllowanceTotal[data.type] = parsedValue;
      this.appliedCreditAllowances[data.type] = false;
      this.error = null;
    },
    resetCreditAllowance() {
      this.creditAllowanceTotal = { allowance: 0, credit: 0 };
      this.updated = Date.now();
    },
  },
};
